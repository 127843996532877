import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "wasBekommtMiracl";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    „Der Prozess des Wohnungskaufs ist sehr aufwendig und ich habe die Erfahrung gemacht, dass viele
                    Menschen beim Thema Finanzierung zurückschrecken. Sie trauen es sich nicht zu, dass sie sich das
                    leisten können.” David Savasci, Gründer von miracl.
                </p>

                <p>
                    Genau dieses Problem wollen wir bei miracl lösen. „Den Hauskauf so einfach wie das Onlineshopping
                    eines Buches zu gestalten” ist unsere Mission, welche uns jeden Tag aufs neue motiviert, unseren
                    Service zu verbessern.
                </p>

                <p>
                    <u>Das beste dabei:</u> Alle unsere Beratungsleistungen sind für Dich <b>kostenlos</b>! Erst bei
                    erfolgreicher Vermittlung deiner Wunschfinanzierung kommt es zu einer Vergütung,{" "}
                    <b>welche wir direkt mit der Bank abrechnen</b>, wodurch keine Zusatzkosten für dich entstehen.
                </p>

                <p>
                    Hast du noch weitere Fragen zu miracl? Du kannst uns gerne jederzeit kontaktieren oder in unseren{" "}
                    <a href={"/faq"}>FAQs</a> mehr über unseren Service erfahren.
                </p>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData page={key} heading={question} description={answer} datePublished="2022-09-12" dateModified="2022-09-12" />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
